
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import usePopupControl, { PopupKey } from "@/lib/compositional-logic/usePopupControl";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { RepositoryFactory, EmailRepository } from "@/lib/https";
import useEmail from "@/views/pages/email/email"

export default defineComponent({
  name: "EmailPreviewPopup",
  components: {
    FlatButton,
    Popup,
  },
  emits: ["onSetDefaultEmailTemplate"],
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      templateData: {
        id: props.id,
        emailTypeId: "",
        name: "",
        message: "",
      },
    });

    const { getEmailTemplate } = RepositoryFactory.getRepository<EmailRepository>(EmailRepository);
    const onGetEmailTemplate = async () => {
      const response = await getEmailTemplate(props.id);
      if (response) {
        state.templateData = response;
      }
    };

    onMounted(onGetEmailTemplate);

    const { handleCopyEmailTemplate, sanitizeHtmlEmail } = useEmail();

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "showEmailPreview";
    const close = () => {
      closePopup(popupKey);
    };

    const sanitizedEditorData = computed(() => sanitizeHtmlEmail(state.templateData.message));

    return {
      ...toRefs(state),
      handleCopyEmailTemplate,
      sanitizedEditorData,
      popupKey,
      close,
    };
  },
});
