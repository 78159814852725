import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d20f8f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "group-detail"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_PointingAngleLeft = _resolveComponent("PointingAngleLeft")!
  const _component_ItemInList = _resolveComponent("ItemInList")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_ConfirmDeleteEmailPopup = _resolveComponent("ConfirmDeleteEmailPopup")!
  const _component_EmailPreviewPopup = _resolveComponent("EmailPreviewPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, null, {
      "top-part": _withCtx(() => [
        _createVNode(_component_Tabs, { "tab-tags": _ctx.tabTags }, null, 8, ["tab-tags"])
      ]),
      "left-top-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("email.contentHeader")), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("email.emailManagementName")), 1)
      ]),
      "left-bottom-part": _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listEmailType, (email, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            _createVNode(_component_ItemInList, {
              "has-divider": "",
              class: "email-list-item"
            }, {
              head: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('icons/mail.svg'),
                  alt: "avatar",
                  class: "mr-2 avatar"
                }, null, 8, _hoisted_3),
                _createElementVNode("span", {
                  class: "email-title",
                  onClick: ($event: any) => (_ctx.onToggleExpand(i))
                }, _toDisplayString(email.name), 9, _hoisted_4)
              ]),
              body: _withCtx(() => [
                _createVNode(_component_TextButton, {
                  class: "add-email-btn",
                  icon: require('icons/add.svg'),
                  text: _ctx.$t('email.addManagement'),
                  enabled: _ctx.permissions.emailManagement == 1,
                  onOnClick: ($event: any) => (_ctx.handleAddEmail(email.id, email.name))
                }, null, 8, ["icon", "text", "enabled", "onOnClick"])
              ]),
              tail: _withCtx(() => [
                _createVNode(_component_PointingAngleLeft, {
                  class: _normalizeClass({
                'show-detail-email': _ctx.selectedIndexMain === i,
              }),
                  onClick: ($event: any) => (_ctx.onToggleExpand(i))
                }, null, 8, ["class", "onClick"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_Transition, { name: "fadeHeight" }, {
              default: _withCtx(() => [
                (_ctx.selectedIndexMain === i)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(email.emailTemplates, (template, j) => {
                        return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                          key: j,
                          actions: _ctx.actionDropdown(template),
                          "has-divider": "",
                          class: "sub-item-list"
                        }, {
                          "item-title": _withCtx(() => [
                            _createElementVNode("div", {
                              class: "item-title",
                              onClick: ($event: any) => (_ctx.onEmailPreview(template.id, template.name))
                            }, [
                              (template.isDefaultTemplate)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: require('icons/crown.svg'),
                                    alt: "crown",
                                    class: "crown-icon mr-1"
                                  }, null, 8, _hoisted_7))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                class: _normalizeClass(["title-mail", {
                      'title-mail-current': template.isDefaultTemplate,
                    }])
                              }, _toDisplayString(template.name), 3)
                            ], 8, _hoisted_6)
                          ]),
                          _: 2
                        }, 1032, ["actions"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ], 64))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.popupState.confirmAction)
      ? (_openBlock(), _createBlock(_component_ConfirmDeleteEmailPopup, _mergeProps({ key: 0 }, _ctx.emailToDelete, { onOnDone: _ctx.onDeleteDone }), null, 16, ["onOnDone"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.showEmailPreview)
      ? (_openBlock(), _createBlock(_component_EmailPreviewPopup, _mergeProps({ key: 1 }, _ctx.emailToPreview, { onOnSetDefaultEmailTemplate: _ctx.onSetDefaultEmailTemplate }), null, 16, ["onOnSetDefaultEmailTemplate"]))
      : _createCommentVNode("", true)
  ], 64))
}