import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-405f4aac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSetDefaultEmailTemplate', _ctx.templateData.id))),
        class: "use-btn",
        text: _ctx.$t('email.setAsCurrent')
      }, null, 8, ["text"]),
      _createVNode(_component_FlatButton, {
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleCopyEmailTemplate(_ctx.templateData.emailTypeId, _ctx.templateData.id))),
        class: "confirm-btn",
        text: _ctx.$t('email.copy')
      }, null, 8, ["text"]),
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.close,
        class: "cancel-btn",
        text: _ctx.$t('login.forgotPassCancelBtn')
      }, null, 8, ["onOnClick", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.sanitizedEditorData }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}