
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import Tabs from "@/components/atomics/Tabs.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import PointingAngleLeft from "@/assets/icons/pointing-angle-icon.vue";
import { RepositoryFactory, EmailRepository } from "@/lib/https";
import { useI18n } from "vue-i18n";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import { useRouter } from "vue-router";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import EmailI, { EmailTemplate } from "@/models/Email";
import ConfirmDeleteEmailPopup from "@/components/popups/ConfirmPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import { AnonymousFunction } from "@/models/Function";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useAccount from "@/lib/compositional-logic/useAccount";
import useEmail from "@/views/pages/email/email"
import EmailPreviewPopup from "@/components/popups/email/EmailPreviewPopup.vue"

export default defineComponent({
  name: "Email",
  components: {
    Tabs,
    TextButton,
    PointingAngleLeft,
    AfterLoginPage,
    ListItemActionDropdown,
    ItemInList,
    ConfirmDeleteEmailPopup,
    EmailPreviewPopup,
  },
  setup() {
    const { t } = useI18n();
    const { openNotification } = useNotificationControl();
    const { popupState, openPopup, closePopup } = usePopupControl();
    const router = useRouter();
    const { permissions } = useAccount();

    const state = reactive({
      listEmailType: [] as EmailI[],
      selectedIndexMain: -1,
      emailToDelete: {
        id: "",
        title: t("email.deleteEmail"),
        message: "",
        action: AnonymousFunction,
      },
      emailToPreview: {
        title: "",
        id: "",
      }
    });

    const tabTags = computed(() => [
      {
        name: t("email.tabtitle"),
        total: state.listEmailType.length,
      },
    ]);

    const { handleCopyEmailTemplate } = useEmail();

    const actionDropdown = (template: EmailTemplate) => [
      {
        action: () => {
          onSetDefaultEmailTemplate(template.id);
        },
        icon: "icons/crown.svg",
        text: t("email.setAsCurrent"),
        disabled: permissions.emailManagement != 1,
      },
      {
        action: () => {
          handleEditEmailTemplate(template.emailTypeId, template.id);
        },
        icon: "icons/edit.svg",
        text: t("email.edit"),
        disabled: permissions.emailManagement != 1,
      },
      {
        action: () => {
          handleCopyEmailTemplate(template.emailTypeId, template.id);
        },
        icon: "icons/content_copy_orange.svg",
        text: t("email.copy"),
        disabled: permissions.emailManagement != 1,
      },
      {
        action: () => {
          onDeleteEmailTemplate({ name: template.name, id: template.id });
        },
        icon: "icons/delete.svg",
        text: t("email.delete"),
        isDelete: true,
        disabled: permissions.emailManagement != 1,
      },
    ];

    const { getListEmailType, setDefaultEmailTemplate, deleteEmailTemplate } =
      RepositoryFactory.getRepository<EmailRepository>(EmailRepository);

    const onGetListEmailType = async () => {
      const response = await getListEmailType();
      if (response) {
        state.listEmailType = response;
      }
    };

    const onSetDefaultEmailTemplate = async (emailTemplateId: string) => {
      const response = await setDefaultEmailTemplate(emailTemplateId);
      if (response && response.error) {
        openNotification({ type: 'error', text: response.error })
      } else if (response) {
        const emailTypeId = response.emailTypeId;
        const emailType = state.listEmailType.find(
          (item) => item.id === emailTypeId
        );
        if (!emailType) return;
        emailType.emailTemplates = emailType.emailTemplates.map((item) => ({
          ...item,
          isDefaultTemplate: false,
        }));
        // Set default value for selected template
        const emailTypeDefault = emailType.emailTemplates.find(
          (item) => item.id === response.id
        );
        if (!emailTypeDefault) return;
        emailTypeDefault.isDefaultTemplate = true;
        openNotification();
      }
      closePopup("showEmailPreview");
    };

    const onDeleteEmailTemplate = async ({ id = "", name = "" }) => {
      state.emailToDelete.id = id;
      state.emailToDelete.action = deleteEmailTemplate;
      state.emailToDelete.message = t("email.deleteEmailMessage", {
        name,
      });
      openPopup("confirmAction");
    };

    const onDeleteDone = () => {
      onGetListEmailType();
      openNotification();
    };

    const onEmailPreview = async(id: string, name: string) => {
      state.emailToPreview.title = name;
      state.emailToPreview.id = id;
      openPopup("showEmailPreview");
    };

    onMounted(onGetListEmailType);

    const handleAddEmail = (emailTypeId: string, emailTypeName: string) => {
      router.push({
        name: "EmailAdd",
        params: {
          emailTypeId,
          emailTypeName,
        },
      });
    };

    const handleEditEmailTemplate = (
      emailTypeId: string,
      emailTemplateId: string
    ) => {
      router.push({
        name: "EmailEdit",
        params: {
          emailTypeId,
          emailTemplateId,
        },
      });
    };

    const onToggleExpand = (index: number) => {
      state.selectedIndexMain = state.selectedIndexMain === index ? -1 : index;
    };

    return {
      ...toRefs(state),
      tabTags,
      actionDropdown,
      handleAddEmail,
      onToggleExpand,
      popupState,
      onDeleteDone,
      permissions,
      onEmailPreview,
      onSetDefaultEmailTemplate,
    };
  },
});
